<template>
  <section class="visitor-management-footer-menu icon-foo1">
    <nav class="menu">
      <ul class="menu-items">
        <li class="menu-item visitor">
          <button @click="setGroup('user.full_name')">
            <icon icon="#cx-vis1-visitor" />
            {{ displayLabelName("visitor-management", "footer", "visitor") }}
          </button>
        </li>
        <li class="menu-item meeting">
          <button @click="setGroup('booking.subject')">
            <icon icon="#cx-vis1-meeting" />
            {{ displayLabelName("visitor-management", "footer", "meeting") }}
          </button>
        </li>
        <li
          v-if="!$route.name.includes('my-visitors')"
          class="menu-item central-item icon-foo2"
        >
          <slot></slot>
        </li>
        <li class="menu-item company">
          <button @click="setGroup('user.company')">
            <icon icon="#cx-vis1-company" />
            {{ displayLabelName("visitor-management", "footer", "company") }}
          </button>
        </li>
        <li class="menu-item host" v-if="!$route.name.includes('my-visitors')">
          <button @click="setGroup('host.full_name')">
            <icon icon="#cx-vis1-host" />
            {{ displayLabelName("visitor-management", "footer", "host") }}
          </button>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  name: "VisitorManagementFooterMenu",
  props: {
    setGroup: {
      type: Function,
      required: true,
    },
  },
};
</script>
